.hero-wrapper {
  position: relative;
  min-height: 60vh;
  display: flex;
  align-items: center;
  padding: 2rem 1rem;
  overflow: hidden;
  background-color: var(--background-color);
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.hero-content {
  text-align: center;
  position: relative;
  z-index: 1;
}

.hero-title {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
}

.hero-subtitle {
  font-size: 1.25rem;
  color: var(--text-secondary);
  margin-bottom: 1.5rem;
}

.card-button {
  padding: 0.5rem 1.5rem;
}

.hero-text-wrapper {
  flex: 1;
  max-width: 600px;
}

.badge-wrapper {
  margin-bottom: 1.5rem;
}

.hero-button {
  background: var(--primary-color);
  border: none;
  padding: 1rem 2rem;
  border-radius: 12px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.button-arrow {
  transition: transform 0.3s ease;
}

.hero-button:hover .button-arrow {
  transform: translateX(4px);
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@media (max-width: 992px) {
  .hero-content {
    flex-direction: column;
    text-align: center;
  }
  
  .hero-title {
    font-size: 3.5rem;
  }
  
  .floating-frame {
    width: 100%;
  }
} 