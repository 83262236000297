body {
  font-family: "Poppins", sans-serif;
  background-color: #f8f9fa;
  color: #333;
}

.content-wrapper {
  text-align: center;
  padding: 100px 20px;
  background: linear-gradient(135deg, #007bff, #00c6ff);
  color: #fff;
}

.subtitle {
  font-size: 1.5rem;
  margin-bottom: 50px;
  opacity: 0.9;
}

.main-title {
  font-size: 3.5rem;
  margin-bottom: 20px;
  font-weight: 700;
}

@media (max-width: 768px) {
  .main-title {
    font-size: 2.5rem;
  }

  .subtitle {
    font-size: 1.2rem;
  }
}

@media (max-width: 576px) {
  .main-title {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1rem;
  }
}
