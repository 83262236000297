.stats-section {
  padding: 60px 20px;
  background-color: #f8f8f8;
}

.stat-card {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  padding: 30px;
  text-align: center;
  transition: transform 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-10px);
}

.stat-number {
  font-size: 3rem;
  font-weight: 700;
  color: #000000;
}

.stat-card p {
  color: #404040;
  font-weight: 500;
}
