.contact-section {
  padding: 60px 20px;
  background-color: #ffffff;
}

.contact-form {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  margin-top: 2rem;
  width: 100%;
}

.form-control {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.8rem;
  margin-bottom: 1rem;
}

.form-control:focus {
  border-color: #000000;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.1);
}

.contact-form .btn-primary {
  background: #000000;
  color: white;
  border: 2px solid #000000;
  border-radius: 30px;
  padding: 0.8rem 2rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
}

.contact-form .btn-primary:hover {
  background: transparent;
  color: #000000;
  transform: translateY(-2px);
}

.contact-title {
  color: #000000;
  margin-bottom: 1rem;
}

.subtitle {
  color: #404040;
}

.content-wrapper {
  min-height: 60vh;
  display: flex;
  align-items: center;
  padding: 4rem 2rem;
  background: radial-gradient(circle at top right, rgba(0, 0, 0, 0.02), transparent),
              radial-gradient(circle at bottom left, rgba(0, 0, 0, 0.02), transparent);
}

.contact-info {
  margin-top: 2rem;
}

.email-link {
  color: #000;
  text-decoration: none;
  font-weight: 600;
  border-bottom: 2px solid #000;
  transition: all 0.3s ease;
}

.email-link:hover {
  opacity: 0.7;
  color: #000;
}

.lead {
  font-size: 1.25rem;
  color: #404040;
}

@media (max-width: 768px) {
  .content-wrapper {
    padding: 3rem 1rem;
  }
}
