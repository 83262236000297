/* Modern Base Styles */
:root {
  --primary-gradient: linear-gradient(45deg, #000000, #404040);
  --surface-color: #ffffff;
  --text-primary: #000000;
  --text-secondary: #404040;
  --accent-color: #000000;
  --background-color: #f5f5f7; /* Apple-style light gray */
}

body {
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
  color: var(--text-primary);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
}

/* Modern Layout Components */
.content-wrapper {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
  background: 
    radial-gradient(circle at top right, rgba(0, 0, 0, 0.03), transparent),
    radial-gradient(circle at bottom left, rgba(0, 0, 0, 0.03), transparent);
}

/* Typography */
.gradient-text {
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.main-title {
  font-size: clamp(2.5rem, 5vw, 4rem);
  font-weight: 800;
  margin-bottom: 1rem;
  letter-spacing: -1px;
}

.subtitle {
  font-size: clamp(1.1rem, 2vw, 1.5rem);
  color: var(--text-secondary);
  margin-bottom: 2rem;
  line-height: 1.6;
}

/* Modern Button Styles */
.cta-button {
  background: var(--primary-gradient);
  border: none;
  padding: 1rem 2.5rem;
  border-radius: 50px;
  font-weight: 600;
  letter-spacing: 0.5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

/* Modern Card Styles */
.card {
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

/* Animations */
@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-up {
  animation: fadeUp 0.8s ease forwards;
}

/* Responsive Design */
@media (max-width: 768px) {
  .content-wrapper {
    padding: 1rem;
  }
  
  .card {
    margin: 1rem;
  }
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--surface-color);
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #FF6B6B88, #4ECDC488);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(45deg, #FF6B6B, #4ECDC4);
}

/* Selection Style */
::selection {
  background: #4ECDC4;
  color: white;
}

/* Hero Section Styles */
.hero-wrapper {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 4rem 2rem;
  background: 
    radial-gradient(circle at top right, rgba(0, 0, 0, 0.02), transparent),
    radial-gradient(circle at bottom left, rgba(0, 0, 0, 0.02), transparent);
}

.hero-title {
  font-size: clamp(2.5rem, 4vw, 3.5rem);
  font-weight: 800;
  margin-bottom: 0.5rem;
  letter-spacing: -1px;
}

.hero-subtitle {
  font-size: clamp(1rem, 1.5vw, 1.2rem);
  color: var(--text-secondary);
  margin-bottom: 1.5rem;
  line-height: 1.4;
}

.hero-button {
  background: #000000;
  color: white;
  border: 2px solid #000000;
  border-radius: 30px;
  padding: 0.8rem 2rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
}

.hero-button:hover {
  background: transparent;
  color: #000000;
  border-color: #000000;
  transform: translateY(-2px);
}

/* Update all other buttons to match this style */
.card-button, 
.cta-button,
.btn-primary {
  background: #000000;
  color: white;
  border: 2px solid #000000;
  border-radius: 30px;
  padding: 0.8rem 2rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
}

.card-button:hover,
.cta-button:hover,
.btn-primary:hover {
  background: transparent;
  color: #000000;
  border-color: #000000;
  transform: translateY(-2px);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .hero-wrapper {
    min-height: 50vh;
    padding: 3rem 1rem;
  }
}

/* Shared styles for Hero, About, and Contact sections */
.hero-wrapper,
.content-wrapper {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 4rem 2rem;
  background: 
    radial-gradient(circle at top right, rgba(0, 0, 0, 0.02), transparent),
    radial-gradient(circle at bottom left, rgba(0, 0, 0, 0.02), transparent);
}

/* Shared typography styles */
.main-title,
.contact-title,
.about-title {
  font-size: clamp(2.5rem, 4vw, 3.5rem);
  font-weight: 800;
  margin-bottom: 0.5rem;
  letter-spacing: -1px;
  color: #000000;
}

.subtitle,
.hero-subtitle {
  font-size: clamp(1rem, 1.5vw, 1.2rem);
  color: #404040;
  margin-bottom: 1.5rem;
  line-height: 1.4;
}
