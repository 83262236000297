footer {
  padding: 30px 0;
  background-color: #000000;
  color: #ffffff;
}

footer a {
  color: #ffffff !important;
  text-decoration: none;
  transition: opacity 0.3s ease;
}

footer a:hover {
  opacity: 0.8;
}
