.about-section {
  padding: 60px 20px;
  background-color: #ffffff;
}

.lead {
  color: #000000;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.6;
}

.about-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.about-logo {
  width: 80px;  /* Increased size */
  height: 80px; /* Increased size */
  transition: transform 0.2s ease;
}

.about-logo:hover {
  animation: shake 0.5s ease-in-out;
}

/* Reuse the same shake animation from Navbar */
@keyframes shake {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-10deg); }
  50% { transform: rotate(10deg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}
